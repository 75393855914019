<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :text="i18n.t('Views.Book.booking')"
      :title="$t('Views.Book.book_for_colleague')"
    >
      <h5 class="header-title">
        {{ $t('Views.Book.book_for_colleague') }}
      </h5>
    </Header>
    <Card
      class="search_card"
    >
      <SearchField :place-holder-text="$t('Views.Book.search_organizer')" />
    </Card>
    <div class="scrollable">
      <SearchResults
        :results="recentlyClickedResults?.reverse()"
        :resources="false"
        :users="true"
        @onResultClick="setBookee"
      />

      <Card
        v-if="bookingTemplate.Owner"
        :header="$t('Components.BookingDetails.organizer')"
        position="bottom no-padding-top"
      >
        <div
          class="orgnaizer_avatar_container margin margin-top"
        >
          <div class="orgianizer_container">
            <PersonAvatar
              v-if="UserState.hasLoadedOnce"
              :src="ownerAvatar"
              height="50px"
              width="50px"
              :border="false"
              :user="bookingTemplate.Owner"
            />
            <p class="normal-semi">
              {{ ownerName }}
            </p>
          </div>
          <div @click="setBookee({})">
            <svg-icon
              class="remove_icon"
              src="/icons/delete_cross.svg"
            />
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Search from '@/Services/Search';
// import BookingService from '@/Services/Bookings/bookings.service';
import WeekState from '@/singletons/week.state.singleton';
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
// import Button from '@/components/button/button.vue';
import UserState from '@/singletons/user.state.singleton';
import SearchField from '@/views/search/components/search.field.vue';
import SearchResults from '@/views/search/components/search.results.vue';
import i18n from '@/i18n';
import SearchResultState from '@/singletons/search.result.state';
import QueryMixin from '../../mixins/query.mixin';
import bookingTemplate from './template.booking';

export default {
  components: {
    SearchField,
    SearchResults,
    Header,
    Card,
  },
  mixins: [QueryMixin],
  data() {
    return {
      UserState,
      WeekState,
      bookingTemplate,
      i18n,
    };
  },
  computed: {
    ownerName() {
      return bookingTemplate?.Owner?.Name;
    },
    ownerAvatar() {
      return bookingTemplate?.Owner?.avatar;
    },
    recentlyClickedResults() {
      if (!UserState.user?.AbstractUser || Search.searchText || bookingTemplate.Owner) return null;
      return SearchResultState.recentOrganizerResults?.map((it) => it.obj);
    },
  },
  created() {
    if (!bookingTemplate.From) {
      this.$router.go(-1);
    }
  },
  mounted() {
    Search.fetchNewSearchOptions();
    this.replaceQuery({ date: WeekState.date.getDateString() });
    SearchResultState.clearOldOrganizerResults();
  },
  methods: {
    setBookee(bookee) {
      if (UserState.user?.AbstractUser) {
        // Special case for navigator, set back to null if removing organizer
        bookingTemplate.Owner = Object.entries(bookee).length ? bookee : null;
      } else {
        bookingTemplate.Owner = Object.entries(bookee).length ? bookee : UserState.profile;
      }
      Search.searchText = '';

      if (UserState.user?.AbstractUser && bookingTemplate.Owner) {
        this.$router.go(-1);
        SearchResultState.storeClickedOrganizerResult(bookee);
      }
    },
  },
};
</script>

<style lang="scss">

.orgnaizer_avatar_container{
  gap:1rem;
  display: flex;
  justify-content: space-between;
  .orgianizer_container{
    display: flex;
    gap: 1rem;
    p{
      margin: auto 0;
    }
  }
  div{
    display: flex;
    .remove_icon{
      margin: auto 0;
      :hover{
        cursor: pointer;
      }
      svg{
        fill: var(--secondary-font-color);
        color: var(--secondary-font-color);
        width: 1.2rem;
      }
    }
  }
}

.search_card {
  margin-bottom: 1rem;
  padding: 0.5rem 0rem;
}

</style>
