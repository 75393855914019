<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :text="backText"
      :route="routeBack"
      :title="resourceName"
    >
      <h5 class="header-title">
        {{ resourceName }}
      </h5>
    </Header>
    <div class="scrollable">
      <Card>
        <WeeklyDateSelector
          :week-length="WeekState.weekLength"
          :week-start="WeekState.date"
          :booking-notifications="bookingNotifications"
          @date-selected="dateChanged"
          @week-changed="weekChanged"
        />
      </Card>
      <Card>
        <p class="margin margin-bottom normal-semi">
          {{ $t('Views.Book.title') }}
        </p>
        <BFormInput
          v-model="bookingTemplate.Subject"
        />
      </Card>
      <Card>
        <div class="booking_items_container margin margin-top margin-bottom">
          <div class="booking_avatar_container">
            <PersonAvatar
              v-if="UserState.hasLoadedOnce"
              :src="UserState.avatar"
              height="50px"
              width="50px"
              :border="false"
              :user="bookingTemplate.Owner"
            />
            <div class="orgianizer_container overflow-hidden">
              <p class="normal-semi elipsis">
                {{ bookingTemplate.Owner ? bookingTemplate.Owner.Name : '' }}
              </p>
              <p class="small-regular">
                {{ $t('Components.BookingDetails.organizer') }}
              </p>
            </div>
          </div>
          <Button
            class="button change-organizer-button"
            size="tiny"
            variant="pastel-primary"
            @click="navigateToOrganizer"
          >
            {{ $t('Views.Book.change') }}
          </Button>
        </div>
      </Card>
      <Card>
        <div class="booking_items_container margin margin-top margin-bottom">
          <p class="normal-semi">
            {{ $t('Views.Book.invited') }}
          </p>
          <Button
            class="booking_items_container--icon"
            variant="pastel-primary"
            size="tiny"
            @click="navigateToAttendees"
          >
            <svg-icon
              class="resource-icon_edit"
              src="/icons/invited_users_icon.svg"
            />
            {{ nrOfInvited }}
          </Button>
        </div>
      </Card>
      <Card>
        <div class="booking_items_container margin margin-top margin-bottom">
          <p class="normal-semi">
            {{ $t('Views.Book.private') }}
          </p>
          <BFormCheckbox
            v-model="bookingTemplate.Private"
            switch
          />
        </div>
      </Card>
      <Card>
        <Timepicker
          :start-date="from"
          :end-date="until"
          :zid="resourceZid"
        />
      </Card>
      <Card
        position="bottom"
      >
        <div class="flex">
          <Button
            v-if="!disableBookButton"
            variant="primary"
            style="margin:auto;"
            :disabled="bookBtnDisabled"
            @click="createBooking"
          >
            {{ $t('Views.Book.book') }}
          </Button>

          <div
            v-else
            class="spinner-medium"
          />
        </div>
      </Card>
    </div>
  </div>
</template>

<script>

/* Components */
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import Button from '@/components/button/button.vue';
import Timepicker from '@/components/timepicker/timepicker.vue';
import WeeklyDateSelector from '@/components/weeklydateselector/weekly.dateselector.vue';
import { BFormInput, BFormCheckbox } from 'bootstrap-vue';

/* Controllers */
import TimepickerController from '@/components/timepicker/timepicker.controller';

/* States */
import WeekState from '@/singletons/week.state.singleton';
import UserState from '@/singletons/user.state.singleton';
import ZoneState from '@/singletons/zone.state.singleton';
import CompanyState from '@/singletons/company.state.singleton';

/* Services */
import UserService from '@/Services/User/user.service';
import ErrorMessage from '@/Services/Error/Error';
import BookingService from '@/Services/Bookings/bookings.service';

/* Classes & Functions */
import CustomEvent from '@/classes/custom.event.class';
import { createQueryStringFromObject } from '@/functions/filter.query.functions';
import { getBookingNotifications } from '@/functions/date.notification.functions';
import ExtendedDate from '../../classes/extended.date.class';

/* Other */
import QueryMixin from '../../mixins/query.mixin';
import bookingTemplate from './template.booking';

export default {
  components: {
    BFormInput,
    BFormCheckbox,
    WeeklyDateSelector,
    Header,
    Card,
    Button,
    Timepicker,
  },
  mixins: [QueryMixin],
  data() {
    return {
      UserState,
      WeekState,
      TimepickerController,
      CompanyState,
      bookingTemplate,
      keepTemplate: false,
      fromDate: null,
      bookingNotifications: [],
      disableBookButton: false,
    };
  },
  computed: {
    nrOfInvited() { return bookingTemplate.Attendees?.length || this.$t('Views.Book.invite_colleague'); },
    backText() { return CompanyState.zones.find((zone) => zone.Zid === Number(this.$route.params.zid))?.Name; },
    routeBack() { return `/book/${this.$route.params.type}/${this.$route.params.zid}?${createQueryStringFromObject(this.$route.query)}`; },
    resourceZid() { return Number(this.$route.params.zid); },
    keepTimeSelection() { return !!bookingTemplate.From; },
    from() { return this.keepTimeSelection ? new ExtendedDate(bookingTemplate.From) : new ExtendedDate(WeekState.date); },
    until() { return this.keepTimeSelection ? new ExtendedDate(bookingTemplate.Until) : null; },
    bookBtnDisabled() {
      return this.disableBookButton ? true : !TimepickerController.endTimstamp();
    },
    resource() { return ZoneState.activeResources[0]; },
    resourceName() { return this.resource?.Name || this.$t('Views.Book.book'); },
  },
  async created() {
    this.setUpBookingTemplate();
    this.replaceQuery({ date: WeekState.date.getDateString() });
    await this.weekChanged(WeekState.date);
  },
  destroyed() {
    if (!this.$route.params?.keepTemplate && !this.keepTemplate) {
      this.resetBookingData();
    }
  },
  methods: {
    setUpBookingTemplate() {
      if (bookingTemplate.templateFor !== 'customBooking') this.resetBookingData();
      bookingTemplate.templateFor = 'customBooking';
      bookingTemplate.Owner = bookingTemplate.Owner || UserState.profile;
      bookingTemplate.Subject = bookingTemplate.Subject || `${bookingTemplate.Owner?.Name}'s ${this.$t('Views.Book.booking')}`;
      bookingTemplate.Attendees = bookingTemplate.Attendees || [];
      bookingTemplate.Zid = this.$route.params.zid;
      bookingTemplate.Source = 'userweb:floorplan';
    },
    navigateToOrganizer() {
      this.updateBookingTemplateTime();
      this.keepTemplate = true;
      this.$router.push({ name: 'book-organizer', query: this.query });
    },
    navigateToAttendees() {
      this.updateBookingTemplateTime();
      this.keepTemplate = true;
      this.$router.push({ name: 'book-attendees', query: this.query });
    },
    resetBookingData() {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(bookingTemplate)) {
        bookingTemplate[key] = null;
      }
    },
    updateBookingTemplateTime() {
      const { date } = WeekState;
      let from = TimepickerController.startTimestamp();
      let until = TimepickerController.endTimstamp();

      from = (from && from !== until) ? new ExtendedDate(date).setHours(0, 0, 0, 0) + Number(from) : date;
      until = (from && until && from !== until) ? new ExtendedDate(date).setHours(0, 0, 0, 0) + Number(until) : null;

      bookingTemplate.From = from < Date.now() ? Date.now() : from;
      bookingTemplate.Until = until;

      const MIN_15 = 1000 * 60 * 15;
      const now = new ExtendedDate().getTime();
      if ((from - now) < MIN_15) bookingTemplate.CheckIn = from;
    },
    async weekChanged(date) {
      WeekState.changeWeek(date);
      this.replaceQuery({ date: WeekState.date.getDateString() });
      this.bookingNotifications = [];
      await Promise.all([
        UserService.fetchUserBookings(WeekState.weekStart.getTime(), WeekState.weekEnd.getTime()).then(() => {
          this.bookingNotifications = getBookingNotifications(UserState.bookings, WeekState.weekLength);
        }),
        BookingService.getBookingsOfDay(date),
      ]);
      bookingTemplate.From = null;
      bookingTemplate.Until = null;
    },
    dateChanged(date) {
      WeekState.date = date;
      this.replaceQuery({ date: WeekState.date.getDateString() });
      bookingTemplate.From = null;
      bookingTemplate.Until = null;
    },
    async createBooking() {
      this.disableBookButton = true;

      try {
        // Set booking time
        this.updateBookingTemplateTime();
        // Shallow copy to modify booking data to make it go through validation
        const booking = { ...bookingTemplate };
        // Change to owner to only username
        booking.Owner = bookingTemplate.Owner.Username;
        if (booking.Owner !== UserState.username) booking.Source = `onbehalf:${UserState.username}`;
        await BookingService.hardPostBooking(booking);
        const evt = new CustomEvent(this.$t('Messages.created_booking'), 'global_error_message', 'success');
        evt.dispatch();
        this.$router.push({ name: 'agenda', query: { date: this.$route.query.date } });
      } catch (e) {
        let evtMsg;
        if (e.response && e.response.data) {
          evtMsg = ErrorMessage.getBookingError(e.response.data);
        } else evtMsg = ErrorMessage.getBookingError(e);
        const evt = new CustomEvent(evtMsg);
        evt.dispatch();
      }

      this.disableBookButton = false;
    },
  },
};
</script>

<style lang="scss">
.booking_items_container{
  display: flex;
  justify-content: space-between;
  p, .button{
    margin: auto 0;
  }
  .booking_avatar_container {
    gap:1rem;
    display: flex;
    flex: 1;
    overflow: hidden;
    gap: 1rem;

    .orgianizer_container {
      margin-right: 1rem;
    }
  }
}

.booking_items_container--icon {
  margin: auto 0;
  gap:0.5rem;
  display: flex;
  justify-content: space-between;
  svg {
    width: 1rem;
    fill: var(--primary-color);
    color: var(--primary-color);
  }
}

</style>
