<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :text="i18n.t('Views.Book.booking')"
      :title="$t('Views.Book.add_attendees')"
    >
      <h5 class="header-title">
        {{ $t('Views.Book.add_attendees') }}
      </h5>
    </Header>
    <Card class="search_card">
      <SearchField :place-holder-text="$t('Views.Book.search_attendee')" />
    </Card>
    <div class="scrollable">
      <SearchResults
        :resources="false"
        :users="true"
        @onResultClick="addAttendee"
      />
      <Card
        v-if="bookingTemplateExists"
        :header="$t('Views.Book.invited')"
        position="bottom no-padding-top"
      >
        <div
          v-for="attendee in attendeeList"
          :key="attendee.Username"
          class="attendee_avatar_container margin margin-top"
        >
          <div class="attendee_container">
            <PersonAvatar
              v-if="UserState.hasLoadedOnce"
              :src="attendee.avatar"
              height="50px"
              width="50px"
              :border="false"
              :user="attendee"
            />
            <p class="normal-regular">
              {{ attendee.Name }}
            </p>
          </div>
          <div @click="removeAttendee(attendee)">
            <svg-icon
              class="remove_icon"
              src="/icons/delete_cross.svg"
            />
          </div>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import Search from '@/Services/Search';
// import BookingService from '@/Services/Bookings/bookings.service';
import WeekState from '@/singletons/week.state.singleton';
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
// import Button from '@/components/button/button.vue';
import UserState from '@/singletons/user.state.singleton';
import SearchField from '@/views/search/components/search.field.vue';
import SearchResults from '@/views/search/components/search.results.vue';
import i18n from '@/i18n';
import bookingTemplate from './template.booking';
import QueryMixin from '../../mixins/query.mixin';

export default {
  components: {
    SearchField,
    SearchResults,
    Header,
    Card,
  },
  mixins: [QueryMixin],
  data() {
    return {
      UserState,
      WeekState,
      bookingTemplate,
      i18n,
      attendeeList: [],
    };
  },
  computed: {
    bookingTemplateExists() {
      return this.bookingTemplate?.Attendees?.length;
    },
  },
  created() {
    if (!bookingTemplate.From) {
      this.$router.go(-1);
    }
  },
  async mounted() {
    this.replaceQuery({ date: WeekState.date.getDateString() });
    await Search.fetchNewSearchOptions();
    this.setAttendeeList();
  },
  methods: {
    attendeeTemplate(attendee) {
      return {
        AvatarUrl: attendee.AvatarUrl,
        Guests: 0,
        Required: true,
        Status: 'TENTATIVE',
        Username: attendee.Username,
        Name: attendee.Name,
      };
    },
    addAttendee(attendee) {
      const key = attendee.Username;
      const index = bookingTemplate.Attendees.map(({ Username }) => Username).indexOf(key);
      if (index === -1) {
        bookingTemplate.Attendees.push(this.attendeeTemplate(attendee));
        this.attendeeList.push(this.attendeeTemplate(attendee));
      }
      Search.searchText = '';
    },
    setAttendeeList() {
      if (this.bookingTemplate.Attendees) {
        this.bookingTemplate.Attendees.forEach((at) => {
          const atItem = Search.colleagues.Users.find((user) => user.Username === at.Username);
          atItem.Status = at.Status;
          this.attendeeList.push(atItem);
        });
      }
    },
    removeAttendee(attendee) {
      const key = attendee.Username;
      const index = bookingTemplate.Attendees.map(({ Username }) => Username).indexOf(key);
      const ix2 = this.attendeeList.map(({ Username }) => Username).indexOf(key);
      if (index > -1) {
        bookingTemplate.Attendees.splice(index, 1);
        this.attendeeList.splice(ix2, 1);
      }
    },
    navigateToCustom() {
      this.$router.push({ name: 'book-custom', params: { keepTemplate: true } });
    },
  },
};
</script>

<style lang="scss">

.attendee_avatar_container{
  gap:1rem;
  display: flex;
  justify-content: space-between;
  .attendee_container{
    display: flex;
    gap: 1rem;
    p{
      margin: auto 0;
    }
  }
  div{
    display: flex;
    .remove_icon{
      margin: auto 0;
      :hover{
        cursor: pointer;
      }
      svg{
        fill: var(--secondary-font-color);
        color: var(--secondary-font-color);
        width: 1.2rem;
      }
    }
  }
}

.search_card {
  margin-bottom: 1rem;
  padding: 0.5rem 0rem;
}
</style>
